/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  overflow: auto;
  transition: max-height 0.6s ease;
}