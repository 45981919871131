@font-face {
  font-family: 'Museo';
  src: url('../fonts/Museo-500.woff2') format('woff2'),
      url('../fonts/Museo-500.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo';
  src: url('../fonts/Museo-300.woff2') format('woff2'),
      url('../fonts/Museo-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo';
  src: url('../fonts/Museo-700.woff2') format('woff2'),
      url('../fonts/Museo-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-700Italic.woff2') format('woff2'),
      url('../fonts/MuseoSans-700Italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-700.woff2') format('woff2'),
      url('../fonts/MuseoSans-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-300.woff2') format('woff2'),
      url('../fonts/MuseoSans-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-900Italic.woff2') format('woff2'),
      url('../fonts/MuseoSans-900Italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-500Italic.woff2') format('woff2'),
      url('../fonts/MuseoSans-500Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-300Italic.woff2') format('woff2'),
      url('../fonts/MuseoSans-300Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-500.woff2') format('woff2'),
      url('../fonts/MuseoSans-500.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-100.woff2') format('woff2'),
      url('../fonts/MuseoSans-100.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-100Italic.woff2') format('woff2'),
      url('../fonts/MuseoSans-100Italic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-900.woff2') format('woff2'),
      url('../fonts/MuseoSans-900.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-500.woff2') format('woff2'),
      url('../fonts/MuseoSans-500.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-500.woff2') format('woff2'),
      url('../fonts/MuseoSans-500.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/AvenirNext-Regular.woff2') format('woff2'),
      url('../fonts/AvenirNext-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
