/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
  font-family: "Museo Sans", sans-serif;
  font-weight: 500;
}

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  padding-bottom: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-NavBar {
  display: flex;
  width: 100px;
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.8em;
  left: auto;
  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  padding: 0.5rem;
  background-color: #93c178;
  border-radius: 100%;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2.1em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='13' viewBox='0 0 9 13'%3E%3Cdefs%3E%3CclipPath id='49aaa'%3E%3Cpath fill='%23fff' d='M-.236 3.025l4.63 4.55 4.6-4.55 1.4 1.35-6 6-6-6z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg%3E%3Cg transform='rotate(92 4.5 6.5)'%3E%3Cpath fill='%23465c8b' d='M-.236 3.025l4.63 4.55 4.6-4.55 1.4 1.35-6 6-6-6z'/%3E%3Cpath fill='none' stroke='%23ced0da' stroke-miterlimit='20' stroke-width='2' d='M-.236 3.025v0l4.63 4.55v0l4.6-4.55v0l1.4 1.35v0l-6 6v0l-6-6v0z' clip-path='url(&quot;%2349aaa&quot;)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 10px;
}

.DayPicker-NavButton--next {
  padding: 0.5rem;
  background-color: #93c178;
  border-radius: 100%;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='13' viewBox='0 0 9 13'%3E%3Cdefs%3E%3CclipPath id='49aaa'%3E%3Cpath fill='%23fff' d='M-.236 3.025l4.63 4.55 4.6-4.55 1.4 1.35-6 6-6-6z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg%3E%3Cg transform='rotate(92 4.5 6.5)'%3E%3Cpath fill='%23465c8b' d='M-.236 3.025l4.63 4.55 4.6-4.55 1.4 1.35-6 6-6-6z'/%3E%3Cpath fill='none' stroke='%23ced0da' stroke-miterlimit='20' stroke-width='2' d='M-.236 3.025v0l4.63 4.55v0l4.6-4.55v0l1.4 1.35v0l-6 6v0l-6-6v0z' clip-path='url(&quot;%2349aaa&quot;)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 10px;
  background-position: 45% 55%;
  transform: rotate(180deg);
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  letter-spacing: 0.59px;
  font-size: 15px;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;

}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  text-align: center;
  color: rgba(52, 72, 94, 0.54);
  font-size: 1rem;
  font-weight: 600;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 1rem;
  font-size: 15px;
  line-height: 1;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #93c178;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #465c8b;
  color: #f0f8ff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #465c8b;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
}

/* DayPickerInput */

.DayPickerInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 2;
}

.DayPickerInput input {
  background-color: rgba(168, 172, 175, 0.15);
  padding: 0.75rem 1.4rem;
  padding-left: 6rem;
  border-radius: 0.7rem;
  text-align: end;
  outline: none;
  flex: 2;
  font-weight: bold;
}

.DayPickerInput input::placeholder {
  color: #465c8b;
  font-size: 1rem;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 1;
  background: #eeeeee;
  border-radius: 0.7rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
