::placeholder {
  color: #666;
  font-size: 14px;
}

::selection {
  background-color: #000;
  color: #fff;
}

::focus {
  outline: initial;
}

html,
body {
  width: 100%;
  min-height: 100vh;
}

body {
  font-family: "Museo", sans-serif;
  font-size: 16px;
  line-height: 1.65;
  background-color: #465c8b;
  color: #eeeeee;
  min-width: 320px;

  font-weight: normal;
  position: relative;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

a.active {
  color: #f7ef6a;
}

a.active svg {
  fill: #f7ef6a;
}

.profileImage{
  width: 57px;
  height: 57px;
  background-size: cover !important;
}