@tailwind base;
@tailwind components;
@tailwind utilities;

::placeholder {
  color: #666;
  font-size: 14px;
}

::selection {
  background-color: #000;
  color: #fff;
}

::focus {
  outline: initial;
}

html, body {
  width: 100%;
  min-height: 100vh;
}

body {
  font-family: "Museo", sans-serif;
  font-size: 16px;
  line-height: 1.65;
  background-color: #465c8b;
  color: #eeeeee;
  min-width: 320px;

  font-weight: normal;
  position: relative;
  overflow-x: hidden;
}

body.about-page {
  background-color: #f7ef6a;
  color: #465c8b;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

header {
  flex: 0 0 auto;
}

main {
  flex: 1 0 auto
}

footer {
  flex: 0 0 auto;
}

.about-page {
  background-image: url(../images/child-bg.png);
  background-repeat: no-repeat;
  background-position: calc(50vw - 550px) 100%;
}

a.selected {
  color: #93c178;
}